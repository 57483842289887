body {
  //font-family: "Gill Sans", sans-serif;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  /*
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  /*
}
#body {
  /*
  margin: 0 auto 0 auto;
  padding: 10px 0 0 0;
  width: 750px;
  */
  text-align: left;
}

.form {
  clear: both;
  margin-top: 15px;
}

footer form {
  display: inline;
}

p#loading {
  height: 300px;
  padding-top: 150px;
  background-image: url('../../../public/images/throbber.gif');
  background-repeat: no-repeat;
  background-position: 50% 130px;
  text-align: center;
}

h1 a {
  color: black;
  text-decoration: none;
}

h3 {
  margin-top: 0px;
  &:not(:first-child) {
    margin-top: 15px;
  }
}

h4 {
  //margin: 0;
}

fieldset {
  margin: 0px;
  padding: 0px;
  border: none;
  /*border-bottom: 1px solid gray;*/
  clear: both;

  &:first-child {
    /*border-top: 1px solid gray;*/
  }
  label,
  select.label {
    width: 140px;
    margin-right: 10px;
    float: left;
    display: block;
  }
  p,
  div {
    margin: 6px 0;
    clear: left;
  }
}

label {
  font-weight: normal;

  &.inline {
    width: auto;
    margin-right: 0px;
    float: none;
    display: inline;
  }
  &.required {
    font-weight: bold;
  }
}

hr {
  display: none;
}

footer {
  border-top: solid 1px black;
  margin-top: 28px;
  padding: 28px 0px;
  font-size: smaller;
  text-align: right;
  clear: both;
}

/*
label.required:after {
    content: "*";
    font-weight: bold;
}
*/

table {
  border-collapse: collapse;

  th {
    text-align: left;
    padding-right: 10px;
    vertical-align: top;
    font-weight: bold;
  }

  table {
    td,
    tr {
      padding: 1px 0;
    }
    th {
      font-weight: normal;
      padding-left: 10px;
    }
  }
}

.action {
  color: #2244bb;
  text-decoration: underline;
  cursor: pointer;
}

.page {
  border-top: 1px solid gray;
}

#controls {
  border-bottom: 1px solid gray;
  padding: 14px 0px;
  clear: both;
}

.prose {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

fieldset .help,
fieldset .instruction,
fieldset .legend,
fieldset .controller,
#controls {
  padding-left: 150px;
}

fieldset .instruction,
fieldset .help {
  font-size: 12px;
}

#controls {
  margin-top: 1em;
}

.disabled.hidden-when-disabled {
  display: none;
}

.disabled {
  color: silver;
}

.not-available {
  display: none;
}

.disabled .not-available {
  display: block !important;
}

.legend {
  font-size: small;
}

.help-icon {
  padding-left: 4px;
  cursor: pointer;
  opacity: 0.3;
  &:hover {
    opacity: 0.7;
  }
}

.close-icon {
  @extend .help-icon;
  float: right;
}

.msg {
  padding-left: 26px;
}

.err {
  background-image: url('../../../public/images/software-update-urgent.png');
  background-repeat: no-repeat;
  background-position: 5px center;
  color: red;
}

.ok {
  padding: 0;
}
