.border-icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  background-color: silver;
  margin: 1px;

  &--all {
    border: solid 1px black;
  }
  &--sides {
    border-left: solid 1px black;
    border-right: solid 1px black;
  }
  &--topbottom {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
  }
  &--left {
    border-left: solid 1px black;
  }

  &.active {
    border: solid 1px green;
  }
}

.button {
  display: inline-block;
  padding: 2px;

  &.active {
    border: solid 1px green;
    padding: 1px;
  }
}

.btn-group-inline {
  display: inline-block;
}

.hidden {
  display: none !important;
}
